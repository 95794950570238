import React from "react";
import {graphql} from "gatsby";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import { setConfiguration } from 'react-grid-system';
import Layout from "./";
import SEO from '../components/Seo';
import "../sass/index.scss";

setConfiguration({gutterWidth: 32});

const SimplePage = (props) => {
  const data = props.data.contentfulSimplePage;

  return (
    <Layout>
      <SEO title={data.title}/>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/iex1rlv.css" />
      </Helmet>
      {data.sections && data.sections.map(section => {
        if (section.__typename === 'ContentfulTextSection') {
          const  ContentfulTextSection = require("../components/TextSection").default;
          return (
            <ContentfulTextSection key={section.id} {...section} />
          )
        }

        return null
      })}
    </Layout>
  )
};

SimplePage.propTypes = {
  data: PropTypes.object,
};

export default SimplePage;

export const simplePageQuery = graphql`
    query($slug: String!) {
        contentfulSimplePage(slug: {eq: $slug}) {
            title,
            sections {
                __typename
                ... on Node {
                    ... on ContentfulTextSection{
                        id,
                        sectionTitle,
                        components {
                            __typename
                            ... on ContentfulTextBlock {
                                id,
                                content {
                                    childMarkdownRemark{
                                        html
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
